

.root {

  .label {
    display: none; 
    @media (min-width: 640px) { 
      display: inline; 
    }
  }
  .labelsm {
    display: inline; 
    @media (min-width: 640px) { 
      display: none; 
    }
  }

}